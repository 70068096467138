import DatePicker from 'react-datepicker';
import styled from 'styled-components';

interface IStyledProps {
  isFilled: boolean;
}

export const StyledContainer = styled.div<IStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  label {
    position: absolute;
    top: ${({ isFilled }) => (isFilled ? '-23px' : '0')};
    left: 0;
    margin: 8px 12px;
    font-size: ${({ isFilled }) => (isFilled ? '12px' : '16px')};
    pointer-events: none;
    transition: all 0.2s ease-out;
    background-color: white;
    padding: 0 5px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const StyledInput = styled(DatePicker)`
  height: 56px;
  width: 242px;
  margin-top: -7px;

  padding: 0 12px;
  padding-bottom: 19px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px #0d6efd;
  }

  &::placeholder {
    color: #aaa;
  }

  &:not(:placeholder-shown) {
    padding-top: 24px;
    border-color: rgba(0, 0, 0, 0.23);
  }

  &[value]:not([value='']) {
    padding-top: 24px;
  }
`;
