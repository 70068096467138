import { AxiosResponse } from 'axios';
import IContractDetails, { ISubscriptionData } from 'model/contract-details';
import IDocument from 'model/document';
import { api } from './api';

const contractApi = () => {
  const getContract = (subscriptionKey: string): Promise<AxiosResponse<IContractDetails>> => {
    return api.get<IContractDetails>(`/person-signature?signatureKey=${subscriptionKey}`);
  };

  const getContractByUuid = (uuid: string): Promise<AxiosResponse<IDocument>> => {
    return api.get<IDocument>(`/documents/${uuid}`);
  };

  const sendTokenToEmail = (subscriptionKey: string): Promise<AxiosResponse<string>> => {
    return api.post<string>(`/token-signatures/send-email?signatureKey=${subscriptionKey}`);
  };

  const signContract = (subscriptionData: ISubscriptionData, subscriptionKey: string): Promise<AxiosResponse<ISubscriptionData>> => {
    return api.patch<ISubscriptionData>(`/person-signature/confirm-data?signatureKey=${subscriptionKey}`, subscriptionData);
  };

  const signEnvelope = (subscriptionData: ISubscriptionData, subscriptionKey: string): Promise<AxiosResponse<ISubscriptionData>> => {
    return api.patch<ISubscriptionData>(`/person-signature/confirm-data-envelope?signatureKey=${subscriptionKey}`, subscriptionData);
  };

  const openContract = (uuid: string, subscriptionKey: string): Promise<AxiosResponse<string>> => {
    return api.patch<string>(`/documents/${uuid}/seen/signature-key/${subscriptionKey}`);
  };

  return {
    getContract,
    getContractByUuid,
    sendTokenToEmail,
    signContract,
    signEnvelope,
    openContract,
  };
};

export default contractApi();
