import ButtonArrow from 'components/button-arrow/ButtonArrow';
import UseCustomStyleContext from 'context/custom-style';
import ICompany from 'model/company';
import IContractDetails from 'model/contract-details';
import LocalStorageKeys from 'model/enums/local-storage-keys';
import { RoutePath } from 'model/enums/routes-path';
import { useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addContracDetails } from 'redux/slice/contract-details';
import { StyledContainer, StyledHeaderContainer, StyledText, StyledTextContainer, StyledTitle } from './styles';

interface IProps extends WithTranslation {
  companyData: ICompany;
}
const BusinessCard = ({ companyData, t }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setColorPrimary, setColorSecundary, setLogo } = useContext(UseCustomStyleContext);

  const handleOnClick = () => {
    localStorage.setItem(LocalStorageKeys.SIGNATURE_COMPANY_NAME, companyData.name);
    localStorage.setItem(LocalStorageKeys.SIGNATURE_UUID, companyData.uuid);

    setColorPrimary(companyData.colorPrimary);
    setColorSecundary(companyData.colorSecundary);
    setLogo(companyData.logoLink);

    if (companyData.color) localStorage.setItem(LocalStorageKeys.COLOR, companyData.color);

    const contractDetails: IContractDetails = {
      document: {
        company: {
          colorPrimary: companyData.colorPrimary,
          colorSecundary: companyData.colorSecundary,
          logoLink: companyData.logoLink,
        },
      },
    };

    dispatch(addContracDetails(contractDetails));
    navigate(RoutePath.PENDING_SIGNATURES);
  };

  return (
    <StyledContainer backgroundColor={companyData.colorPrimary} onClick={handleOnClick}>
      <StyledHeaderContainer>
        <StyledTextContainer>
          <img
            src={companyData.logoLink}
            alt={`Logo ${companyData.name}`}
            style={{ maxWidth: '88px', objectFit: 'contain', margin: '0px 0px 12px 0px' }}
          />
          <StyledTitle>{companyData.name}</StyledTitle>
          <StyledText>{t('dashboard.businessCard.contractsPendingSignature', { count: companyData.numberOfDocuments })}</StyledText>
        </StyledTextContainer>
      </StyledHeaderContainer>

      <ButtonArrow text={t('global.button.seeContracts')} onClick={() => navigate('')} style={{ marginBottom: '0px' }} white />
    </StyledContainer>
  );
};

export default withTranslation()(BusinessCard);
