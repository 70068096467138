import { TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
  width: 200px;
  height: 56px;
  padding-left: 14px;

  label {
    font-size: 14px;
    color: #7f7f7f;

    &.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
      transform: translate(0, 0) scale(0.85);
    }
  }

  .MuiOutlinedInput-root {
    height: 56px;
  }

  input {
    height: 100%;
    padding-top: 24px;
    padding-left: 14px;
    text-align: left;
    box-sizing: border-box;
    background: none !important;
  }

  label {
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
`;
