import LocalStorageKeys from 'model/enums/local-storage-keys';
import { createContext } from 'react';
import useLocalStorage from 'react-use-localstorage';
import defaultLogo from '../../assets/images/audit-sign/giro-logo.svg';

interface IUseCustomStyleContextProvider {
  children: JSX.Element;
}

interface IPropsUseCustomStyleContext {
  colorPrimary: string;
  setColorPrimary: (color: string) => void;
  colorSecundary: string;
  setColorSecundary: (color: string) => void;
  setDefault: () => void;
  logo: string;
  setLogo: (logo: string) => void;
}

const DEFAULT_VALUE: IPropsUseCustomStyleContext = {
  colorPrimary: '#0F62FE',
  setColorPrimary: (color: string) => {},
  colorSecundary: '#78A9FF',
  setColorSecundary: (color: string) => {},
  setDefault: () => {},
  logo: defaultLogo,
  setLogo: (logo: string) => {},
};

const UseCustomStyleContext = createContext<IPropsUseCustomStyleContext>(DEFAULT_VALUE);

const UseCustomStyleContextProvider: React.FC<IUseCustomStyleContextProvider> = ({ children }) => {
  const [colorPrimary, setColorPrimary] = useLocalStorage(LocalStorageKeys.COLOR_PRIMARY, DEFAULT_VALUE.colorPrimary);

  const [colorSecundary, setColorSecundary] = useLocalStorage(LocalStorageKeys.COLOR_SECONDARY, DEFAULT_VALUE.colorSecundary);

  const [logo, setLogo] = useLocalStorage(LocalStorageKeys.LOGO, DEFAULT_VALUE.logo);

  const setDefault = () => {
    setColorPrimary(DEFAULT_VALUE.colorPrimary);
    setColorSecundary(DEFAULT_VALUE.colorSecundary);
    setLogo(DEFAULT_VALUE.logo);
  };

  return (
    <UseCustomStyleContext.Provider value={{ colorPrimary, setColorPrimary, colorSecundary, setColorSecundary, setDefault, logo, setLogo }}>
      {children}
    </UseCustomStyleContext.Provider>
  );
};

export { UseCustomStyleContextProvider };
export default UseCustomStyleContext;
