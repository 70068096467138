import BusinessCard from 'components/business-card/BusinessCard';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import SmallLoading from 'components/small-loading/SmallLoading';
import UseCustomStyleContext from 'context/custom-style';
import ICompany from 'model/company';
import IContractDetails from 'model/contract-details';
import LocalStorageKeys from 'model/enums/local-storage-keys';
import { RoutePath } from 'model/enums/routes-path';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addContracDetails, resetContracDetails } from 'redux/slice/contract-details';
import companyService from 'services/company-service';
import AuthUtils from 'shared/util/auth-utils';
import { StyledCardsContainer, StyledContainer, StyledLoadingContainer } from './styles';

const Dashboard = () => {
  const account = AuthUtils.getAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState<ICompany[]>();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const { setColorPrimary, setColorSecundary, setDefault, setLogo } = useContext(UseCustomStyleContext);

  useEffect(() => {
    companyService.getAllCompanies().then(response => {
      const hasOnlyOneCompany = response.length === 1;

      if (hasOnlyOneCompany) {
        localStorage.setItem(LocalStorageKeys.SIGNATURE_COMPANY_NAME, response[0].name);
        localStorage.setItem(LocalStorageKeys.SIGNATURE_UUID, response[0].uuid);

        setColorPrimary(response[0].colorPrimary);
        setColorSecundary(response[0].colorSecundary);
        setLogo(response[0].logoLink);

        const contractDetails: IContractDetails = {
          document: {
            company: {
              colorPrimary: response[0].colorPrimary,
              colorSecundary: response[0].colorSecundary,
              logoLink: response[0].logoLink,
            },
          },
        };

        dispatch(addContracDetails(contractDetails));
        navigate(RoutePath.PENDING_SIGNATURES, { state: { hasOnlyOneCompany: hasOnlyOneCompany } });
      } else {
        setDefault();
        dispatch(resetContracDetails());
        setCompanies(response);
      }

      setIsPageLoading(false);
    });
  }, []);

  return (
    <StyledContainer>
      {isPageLoading ? (
        <StyledLoadingContainer>
          <SmallLoading />
        </StyledLoadingContainer>
      ) : (
        <>
          <PageTitleSection i18nKeyTitle="dashboard.title" className="title" showBackButton={false} />

          <StyledCardsContainer>
            {companies?.map((company, idx) => (
              <BusinessCard key={`${company.name}${idx}`} companyData={company} />
            ))}
          </StyledCardsContainer>
        </>
      )}
    </StyledContainer>
  );
};

export default Dashboard;
