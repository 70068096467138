import ButtonArrow from 'components/button-arrow/ButtonArrow';
import CalendarFilter from 'components/calendar-filter/CalendarFilter';
import CustomCheckbox from 'components/custom-checkbox/CustomCheckbox';
import GenericModal from 'components/generic-modal/GenericModal';
import GenericTable from 'components/generic-table/GenericTable';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import Status from 'components/status/Status';
import UseCardVerificationContext from 'context/card-verification';
import IContractDetails from 'model/contract-details';
import IDocument, { ITypeContractsFilter } from 'model/document';
import LocalStorageKeys from 'model/enums/local-storage-keys';
import { RoutePath } from 'model/enums/routes-path';
import SignatureMethods from 'model/enums/signature-methods';
import { PageableResponse, PaginationParameters } from 'model/pageable';
import { TypeBatchSubscription } from 'model/type-batch-subscription';
import ITypeGenericTable from 'model/type-generic-table';
import { useContext, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addContracDetails } from 'redux/slice/contract-details';
import companyService from 'services/company-service';
import personService from 'services/person-service';
import AuthUtils from 'shared/util/auth-utils';
import { convertDateTimeFromServer, formatDateLocal } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import { StyledTextField } from 'styled-components/StyledTextField';
import { ReactComponent as XIconSvg } from '../../assets/images/audit-sign/x-icon.svg';
import { ReactComponent as YellowWarningSvg } from '../../assets/images/audit-sign/yellow-warning-amber.svg';
import {
  StyledClearButtonIcon,
  StyledContainer,
  StyledFiltersContainer,
  StyledTableCotainer,
  StyledTableHeader,
  StyledTableHeaderInnerContainer,
  StyledTag,
} from './styles';

const PendingSubscriptions = ({ t }: WithTranslation) => {
  const account = AuthUtils.getAccount();
  const companyName = localStorage.getItem(LocalStorageKeys.SIGNATURE_COMPANY_NAME);
  const uuid = localStorage.getItem(LocalStorageKeys.SIGNATURE_UUID);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hasOnlyOneCompany, companyLoading } = useContext(UseCardVerificationContext);

  const [documents, setDocuments] = useState<PageableResponse<IDocument>>();
  const [page, setPage] = useState<number>(0);
  const [filterTable, setFilterTable] = useState<ITypeContractsFilter>();
  const [timeoutCcbSearch, setTimeoutCcbSearch] = useState<NodeJS.Timeout | null>(null);

  const [listSelectedContracts, setListSelectedContracts] = useState<number[]>([]);
  const [isSelectAllContracts, setIsSelectAllContracts] = useState<boolean>(false);
  const [isShowAlertModal, setIsShowAlertModal] = useState<boolean>(false);
  const [isSubscribeButtonLoading, setIsSubscribeButtonLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsTableLoading(true);

    const pagination: PaginationParameters = {
      page,
      size: 7,
      ...filterTable,
    };

    if (uuid != null) {
      companyService.getAllDocumentsPendingSignature(uuid, pagination).then(response => {
        setDocuments(response);
        setIsTableLoading(false);
      });
    }
  }, [page, filterTable]);

  useEffect(() => {
    if (isSelectAllContracts) {
      setIsShowAlertModal(true);
    }
  }, [isSelectAllContracts]);

  useEffect(() => {
    return () => {
      if (timeoutCcbSearch != null) {
        clearTimeout(timeoutCcbSearch);
      }
    };
  }, []);

  const handleSendBatchSignatureTokenToEmail = () => {
    setIsSubscribeButtonLoading(true);
    const subscriptionData: TypeBatchSubscription = {};

    if (uuid != null) {
      subscriptionData.companyUuid = uuid;
      isSelectAllContracts ? (subscriptionData.isAllDocuments = true) : (subscriptionData.documentsIds = listSelectedContracts);
      subscriptionData.initialDate = filterTable?.initialDate ?? '';
      subscriptionData.finalDate = filterTable?.finalDate ?? '';
      subscriptionData.name = filterTable?.name ?? '';

      companyService.sendBatchSignatureTokenToEmail(subscriptionData).then(response => {
        response.unshift(SignatureMethods.DATA_CONFIRMATION);

        if (account?.login != null) {
          personService.getPersonData(account?.login).then(personData => {
            const contractDetails: IContractDetails = {
              signatureMethods: response,
              name: account?.firstName,
              email: account?.email,
              birth: personData.birth ?? '',
              person: {
                id: personData.id,
                cpf: personData.cpf,
              },
              batchSignatureData: subscriptionData,
            };
            dispatch(addContracDetails(contractDetails));
            setIsSubscribeButtonLoading(false);
            navigate('/etapas-assinatura');
          });
        }
      });
    }
  };

  const handleSelectContract = (contractId: number) => {
    const index = listSelectedContracts.indexOf(contractId);

    if (!isSelectAllContracts) {
      if (index != -1) {
        listSelectedContracts.splice(index, 1);
        setListSelectedContracts([...listSelectedContracts]);
      } else {
        setListSelectedContracts([...listSelectedContracts, contractId]);
      }
    }
  };

  const handleCcbInput = (value: string) => {
    if (timeoutCcbSearch != null) {
      clearTimeout(timeoutCcbSearch);
    }

    setTimeoutCcbSearch(
      setTimeout(() => {
        setFilterTable({ ...filterTable, name: value ?? '' });
      }, 500)
    );
  };

  const handleChangeDateFilter = (date: [Date | null, Date | null]) => {
    setFilterTable({ ...filterTable, initialDate: convertDateTimeFromServer(date[0]), finalDate: convertDateTimeFromServer(date[1]) });
  };

  const tableHeader = () => (
    <th colSpan={5} style={{ border: 'none' }}>
      <StyledTableHeaderInnerContainer>
        <StyledTableHeader>{t('pendingSubscriptions.table.pendingCcb')}</StyledTableHeader>
        <StyledTag>{StringUtils.maskAmount(documents?.totalElements)}</StyledTag>
      </StyledTableHeaderInnerContainer>
    </th>
  );

  const dataGenericTable: ITypeGenericTable = {
    headerData: [
      <CustomCheckbox
        selected={isSelectAllContracts}
        text={t('genericTable.selectAll')}
        handleClick={() => {
          setIsSelectAllContracts(!isSelectAllContracts);
          setListSelectedContracts([]);
        }}
      />,
      t('pendingSubscriptions.table.contractName'),
      t('pendingSubscriptions.table.date'),
      t('pendingSubscriptions.table.status'),
      '',
    ],
    tableData: documents?.content.map(document => ({
      select: (
        <CustomCheckbox
          selected={listSelectedContracts.includes(document.id) || isSelectAllContracts}
          handleClick={() => handleSelectContract(document.id)}
        />
      ),
      name: document.name,
      data: formatDateLocal(document.originalDocumentDate),
      status: <Status status={document.status} />,
      seeContract: (
        <ButtonArrow
          text={t('global.button.seeContract')}
          onClick={() => navigate('/contrato', { state: document })}
          style={{ margin: '0px' }}
        />
      ),
    })),
  };

  return (
    <StyledContainer>
      <PageTitleSection
        i18nKeyTitle={companyName ?? ''}
        i18nKeyMainButtonText="global.button.toSign"
        mainButtonLoading={isSubscribeButtonLoading}
        handleClickMainButton={() => handleSendBatchSignatureTokenToEmail()}
        i18nKeyButtonText={listSelectedContracts.length > 0 || isSelectAllContracts ? 'global.button.clearSelection' : ''}
        handleClickButton={() => {
          setIsSelectAllContracts(false);
          setListSelectedContracts([]);
        }}
        iconButton={
          <StyledClearButtonIcon>
            <XIconSvg />
          </StyledClearButtonIcon>
        }
        disableMainButton={(listSelectedContracts.length < 1 && !isSelectAllContracts) || isSubscribeButtonLoading}
        showBackButton={!hasOnlyOneCompany}
        redirect={() => navigate(RoutePath.DASHBOARD)}
        loadingBackButton={companyLoading}
      />

      <StyledTableCotainer>
        <StyledFiltersContainer>
          <CalendarFilter label={t('pendingSubscriptions.table.selectDate')} onChange={date => handleChangeDateFilter(date)} />
          <StyledTextField
            label={t('pendingSubscriptions.table.contractName')}
            onChange={({ target }) => handleCcbInput(target.value)}
            inputProps={{ maxLength: 255 }}
          />
        </StyledFiltersContainer>
        <GenericTable
          isTableLoading={isTableLoading}
          setIsTableLoading={setIsTableLoading}
          tableHeader={tableHeader()}
          headerData={dataGenericTable.headerData}
          tableData={dataGenericTable.tableData}
          totalPages={documents?.totalPages}
          currentPage={documents?.pageable.pageNumber}
          paginationValue={setPage}
        />
      </StyledTableCotainer>

      <GenericModal
        isOpen={isShowAlertModal}
        onCloseModal={() => setIsShowAlertModal(false)}
        icon={<YellowWarningSvg />}
        title={t('pendingSubscriptions.modal.title')}
        text={t('pendingSubscriptions.modal.text')}
      />
    </StyledContainer>
  );
};

export default withTranslation()(PendingSubscriptions);
