import contractApi from 'api/contract-api';
import axios, { AxiosError, AxiosResponse } from 'axios';
import IContractDetails, { ISubscriptionData } from 'model/contract-details';
import IDocument from 'model/document';

export const contractService = () => {
  const getContract = async (subscriptionKey: string) => {
    try {
      const result: AxiosResponse<IContractDetails> = await contractApi.getContract(subscriptionKey);
      if (result.status === 200 && result.data != null) {
        if (result.data.document?.originalDocument?.presignedUrl != null) {
          const document = await axios.get<string>(result.data.document?.originalDocument.presignedUrl, {
            responseType: 'arraybuffer',
          });

          result.data.documentBase64 = Buffer.from(document.data, 'binary').toString('base64');
        }

        if (result.data?.envelope?.documents != null) {
          await Promise.all(
            result.data.envelope.documents.map(async value => {
              if (value.originalDocument != null) {
                const document = await axios.get(value.originalDocument.presignedUrl, {
                  responseType: 'arraybuffer',
                });

                value.originalDocument.documentBase64 = Buffer.from(document.data, 'binary').toString('base64');
              }
            })
          );
        }

        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getContractByUuid = async (uuid: string) => {
    try {
      const result: AxiosResponse<IDocument> = await contractApi.getContractByUuid(uuid);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const signContract = async (subscriptionData: ISubscriptionData, subscriptionKey: string) => {
    try {
      const result: AxiosResponse<ISubscriptionData> = await contractApi.signContract(subscriptionData, subscriptionKey);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const signEnvelope = async (subscriptionData: ISubscriptionData, subscriptionKey: string) => {
    try {
      const result: AxiosResponse<ISubscriptionData> = await contractApi.signEnvelope(subscriptionData, subscriptionKey);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const openContract = async (uuid: string, subscriptionKey: string) => {
    try {
      const result: AxiosResponse<string> = await contractApi.openContract(uuid, subscriptionKey);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendTokenToEmail = async (subscriptionKey: string) => {
    try {
      const result: AxiosResponse<string> = await contractApi.sendTokenToEmail(subscriptionKey);
      if (result.status === 201 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getContract,
    getContractByUuid,
    sendTokenToEmail,
    signContract,
    openContract,
    signEnvelope,
  };
};

export default contractService();
