import { MOVIDESK_WEBCHAT_KEY, MOVIDESK_WEBCHAT_URL } from 'config/constants';
import useScript from 'hooks/use-script/use-script';
import './movidesk-chat.scss';

export interface MovideskChatProps {
  id: string;
}

declare global {
  interface Window {
    mdChatClient?: string;
  }
}

export const MovideskChat = () => {
  window.mdChatClient = MOVIDESK_WEBCHAT_KEY;
  useScript(MOVIDESK_WEBCHAT_URL);
  return <></>;
};

export default MovideskChat;
